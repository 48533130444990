import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { FormikSubmitButton, FormikTextField } from '@src/components/formik';
import { Form, Formik } from 'formik';
import { analytics } from '@src/lib/analytics';
import { useSnackbarMessenger } from '@src/hooks/use-snackbar-messenger';
import { FormControl, Grid } from '@material-ui/core';
import { AuthLayout } from '@src/layouts/auth-layout';
import { SignInUserDocument } from '@generated/graphql';
import { useMutation } from '@src/hooks/use-graphql-hooks';
import {
  AppleButton,
  FacebookButton,
  GoogleButton,
  YahooButton,
} from '@src/components/sso-buttons';
import getConfig from 'next/config';
import { neutralDefault, neutralHigh } from '@src/support/colors';
import { initializeSession } from '@src/lib/auth';
import type { AuthSuccessResult } from '@src/apiCalls';

const { NODE_ENV, API_HOST } = getConfig().publicRuntimeConfig;

export function LoginPage() {
  const [showAppleAccountNotFound, setShowAppleAccountNotFound] =
    useState(false);
  const [showGoogleAccountNotFound, setShowGoogleAccountNotFound] =
    useState(false);

  const router = useRouter();
  const [snackbar] = useSnackbarMessenger();

  /**
   * Initiates an SSO redirect (if requested, based on URL parameters).
   * This function should only be invoked after successful user authentication
   * (Email, Google, Facebook, etc.) and as part of the callback process.
   *
   * On detecting a requested SSO redirect, function navigates the user away from the app
   * and initiates an SSO handshake with the requesting Service Provider.
   *
   * @returns [boolean] `true` if redirect was requested and taken, `false` otherwise
   */
  const initiateSingleSignOnRedirectIfNeeded = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('sso_sp')) {
      // SSO redirect is requested

      // For `dev`, ensure domain is normalized (similar to `prod`) so that cross-origin cookie requests work correctly.
      // NOTE: This requirement is not currently expected outside this function/scenario.
      const apiHost = API_HOST.replace('127.0.0.1', 'localhost');

      const ssoURL = new URL(`${apiHost}/sso/auth`);

      // Forward current query params to destination url and redirect.
      // Note we use `window.location` instead of `router` because we're
      // exiting the application and want to ensure a side-effect free redirect.
      ssoURL.search = window.location.search;
      window.location.href = ssoURL.toString();
      return true;
    }
    // no SSO redirect is needed
    return false;
  };

  const [signInUser] = useMutation(SignInUserDocument, {
    onCompleted(data) {
      if (data?.signInUser) {
        initializeSession({
          ...data.signInUser.user,
          uuid: data.signInUser.user.id,
          authToken: data.signInUser.token,
          intercomUserHash: data.signInUser.user.intercomUserHash || undefined,
        });

        if (initiateSingleSignOnRedirectIfNeeded()) {
          // exit from app
          return;
        }

        // otherwise redirect to application
        router.push({ pathname: '/', query: router.query });
      }
    },
  });

  const { email_confirmed } = router.query;

  useEffect(() => {
    if (typeof email_confirmed === 'string') {
      if (email_confirmed === 'true') {
        snackbar.addSuccessMessage('Email verification complete!');
      } else {
        snackbar.addErrorMessage(
          'Sorry, the confirmation link has expired! Please try again'
        );
      }
      router.replace(router.pathname, { query: router.query });
    }
  }, [router, email_confirmed, snackbar]);

  useEffect(() => {
    analytics.page('login');
  }, []);

  const title =
    email_confirmed === 'true'
      ? 'Email Verified! Please Log In'
      : 'Welcome back';

  const redirectURL =
    typeof window === 'undefined'
      ? `${NODE_ENV}/`
      : `${window.location.origin}/`;

  const errorMessage = (status: string) => {
    if (showAppleAccountNotFound) {
      return 'Login failed. You don’t have a Trusted account associated with your Apple account. Please sign up.';
    }

    if (showGoogleAccountNotFound) {
      return 'Login failed. You don’t have a Trusted account associated with your Google account. Please sign up.';
    }

    return status;
  };

  const appleAccountNotFoundCallback = () => {
    setShowAppleAccountNotFound(true);
  };

  const googleAccountNotFoundCallback = () => {
    setShowGoogleAccountNotFound(true);
  };

  const success = (results: AuthSuccessResult) => {
    initializeSession({
      uuid: results.uuid,
      email: results.email,
      firstName: results.first_name,
      lastName: results.last_name,
      authToken: results.auth_token,
      intercomUserHash: results.intercom_user_hash,
    });

    if (initiateSingleSignOnRedirectIfNeeded()) {
      // exit from app
      return;
    }

    // otherwise redirect to application
    router.push('/');
  };

  return (
    <AuthLayout title={title}>
      <Formik
        initialValues={{ password: '', email: '' }}
        onSubmit={async (input, { setStatus }) => {
          setShowAppleAccountNotFound(false);
          setShowGoogleAccountNotFound(false);
          localStorage.removeItem('LAST_USED_SSO');

          try {
            const response = await signInUser({ variables: { input } });
            if (response.errors) {
              setStatus(response.errors[0].message);
            }
          } catch (error: any) {
            setStatus(error.message);
          }
        }}
      >
        {({ status, setStatus, setSubmitting }) => (
          <div>
            <Form placeholder={undefined}>
              <FormikTextField
                autoComplete="email"
                autoFocus
                fullWidth
                label="Email"
                margin="dense"
                name="email"
                placeholder="e.g. f.nightengale@gmail.com"
                type="email"
              />
              <FormikTextField
                autoComplete="off"
                fullWidth
                label="Password"
                margin="dense"
                name="password"
                placeholder="It was at least 6 characters"
                type="password"
              />
              <FormControl margin="dense" fullWidth>
                <p className="help is-danger">{errorMessage(status)}</p>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={4}>
                    <FormikSubmitButton color="primary" shape="rounded">
                      Login
                    </FormikSubmitButton>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container spacing={3} justifyContent="flex-end">
                      <Grid item xs="auto">
                        <Link href="/reset-password" legacyBehavior>
                          <a>Forgot password</a>
                        </Link>
                      </Grid>
                      <Grid item xs="auto">
                        <Link
                          href={{
                            pathname: '/signup',
                            query: router.query,
                          }}
                          legacyBehavior
                        >
                          <a>Sign up</a>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </FormControl>
            </Form>
            <div className="is-divider" data-content="OR" />
            <div className="social-media-button-wrapper-login">
              <FacebookButton
                onSuccess={results => {
                  if ('fb_error' in results || 'error' in results) {
                    setShowAppleAccountNotFound(false);
                    setShowGoogleAccountNotFound(false);
                    setSubmitting(false);
                    setStatus(
                      'fb_error' in results ? results.fb_error : results.error
                    );
                  } else {
                    success(results);
                  }
                }}
              />
              <GoogleButton
                success={results => {
                  if ('google_error' in results || 'error' in results) {
                    setShowAppleAccountNotFound(false);
                    setShowGoogleAccountNotFound(false);
                    setSubmitting(false);
                    setStatus(
                      'google_error' in results
                        ? results.google_error
                        : results.error
                    );
                  } else {
                    success(results);
                  }
                }}
                googleAccountNotFound={googleAccountNotFoundCallback}
              />
              <AppleButton
                success={results => {
                  if ('apple_error' in results || 'error' in results) {
                    setShowAppleAccountNotFound(false);
                    setShowGoogleAccountNotFound(false);
                    setSubmitting(false);
                    setStatus(
                      'apple_error' in results
                        ? results.apple_error
                        : results.error
                    );
                  } else {
                    success(results);
                  }
                }}
                redirectURL={redirectURL}
                appleAccountNotFound={appleAccountNotFoundCallback}
              />
              <YahooButton
                onSuccess={results => {
                  if ('yahoo_error' in results || 'error' in results) {
                    setShowAppleAccountNotFound(false);
                    setShowGoogleAccountNotFound(false);
                    setSubmitting(false);
                    setStatus(
                      'yahoo_error' in results
                        ? results.yahoo_error
                        : results.error
                    );
                  } else {
                    success(results);
                  }
                }}
              />
            </div>
          </div>
        )}
      </Formik>
      <style jsx>{`
        .social-media-button-wrapper-login {
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
          gap: 1rem;
        }
        :global(
            .social-media-button-wrapper-login
              .react-apple-signin-auth-btn-dark.apple-auth-btn
          ) {
          width: 100%;
          border-radius: 5rem;
        }

        .is-divider {
          display: block;
          position: relative;
          border-top: 0.1rem solid ${neutralDefault};
          height: 0.1rem;
          margin: 2rem 0;
          text-align: center;
        }

        .is-divider[data-content]::after {
          background: white;
          color: ${neutralHigh};
          content: attr(data-content);
          display: inline-block;
          font-size: 0.75rem;
          padding: 0.5rem 0.8rem;
          transform: translateY(-1.1rem);
          text-align: center;
        }
      `}</style>
    </AuthLayout>
  );
}
